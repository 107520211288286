import React, { useEffect, useState } from "react"
import { Col, Container, Form, Modal, Row } from "react-bootstrap"

import * as yup from "yup"

import API from "../../common/API"
import { useFormik } from "formik"
import Settings from "../../common/Settings"
import { parsedUser } from "../../common/GetCurrentUser"

const validationSchema = yup.object({
  name: yup.string().required("required"),
  latitude: yup.string().required(),
  longitude: yup.string().required(),
  image: yup.string().required(),
  categoryId: yup.string().required(),
  stageId: yup.string().required(),
})

const AddEditPlace = props => {
  const [isLoading, setIsLoading] = useState(false)
  const user = parsedUser()
  const handleSubmit = async (obj, event) => {
    try {
      console.log("values to submit", obj)
      obj.organizationId = user.OrganizationId
      obj.createdId = user.ID
      let objToBeSent = {
        id: obj.id,
        name: obj.name,
        latitude: obj.latitude,
        longitude: obj.longitude,
        image: obj.image,
        image2: obj.image2,
        note: obj.note,
        categoryId: obj.categoryId,
        stageId: obj.stageId,
        organizationId: obj.organizationId,
        createdId: obj.createdId,
        updatedId: obj.id ? user.ID : null,
      }
      setIsLoading(true)
      let request = await API.postAction("place/AddEdit", objToBeSent)
      setIsLoading(false)

      console.log("response from adding", request)
      if (request.status === 200) {
        props.toggle(null, true)
      }
    } finally {
      setIsLoading(false)
    }
  }
  console.log(props.model)
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: props.model
      ? props.model
      : {
          latitude: null,
          longitude: null,
          id: null,
          name: "",
          image: null,
          image2: null,
          note: null,
          categoryId: null,
          stageId: null,
        },
    validationSchema,
    onSubmit: handleSubmit,
  })
  const [categories, setCategories] = useState([])
  const [stages, setStages] = useState([])

  const [imageField, setImageField] = useState(null)

  const GetLocation = () => {
    var geoSuccess = function (position) {
      // Do magic with location

      console.log("the position", position.coords)
      formik.setFieldValue("latitude", position.coords.latitude)
      formik.setFieldValue("longitude", position.coords.longitude)

      //Now we need to request the information from Google
    }
    var geoError = function (error) {
      switch (error.code) {
        case error.TIMEOUT:
          // The user didn't accept the callout
          // showNudgeBanner();
          break
      }
    }

    navigator.geolocation.getCurrentPosition(geoSuccess, geoError)
  }

  const requestCategories = async () => {
    let requestAPI = await API.getAction("category/List")
    if (requestAPI.data.status === "ok") {
      setCategories(requestAPI.data.response)
    }
  }
  const requestStages = async () => {
    let requestAPI = await API.getAction("stage/List")
    if (requestAPI.data.status === "ok") {
      setStages(requestAPI.data.response)
    }
  }

  const uploadImg = async (obj, field) => {
    let file = obj.target.files[0]
    console.log(file)
    // Ensure it's an image
    if (file.type.match(/image.*/)) {
      console.log("An image has been loaded")
      let bodyData = new FormData()
      bodyData.append("path", file)
      let request = await API.postAction(`File/Upload`, bodyData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      if (request) {
        formik.setFieldValue(field, request.data.response)
      }
    }
  }

  useEffect(() => {
    requestCategories()
    requestStages()
  }, [])

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.toggle}
        size="md"
        className="modalRight"
      >
        <Modal.Header closeButton>
          <Row className="mx-0 w-100">
            <Col>
              <h5>Lugar</h5>
            </Col>
          </Row>
        </Modal.Header>

        <Form onSubmit={formik.handleSubmit}>
          <Modal.Body className="p-0" style={{ overflow: "auto" }}>
            <Container>
              <Row>
                <Col xs={12}>
                  <label>Nombre</label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  ></input>

                  {formik.errors.name ? (
                    <div className="invalid text-sm">{formik.errors.name}</div>
                  ) : null}
                </Col>
                <Col xs={12} className="my-2">
                  <label>Ubicación</label>
                  <button
                    type="button"
                    className="btn btn-sm btn-secondary ms-2 me-2"
                    onClick={() => {
                      GetLocation()
                    }}
                  >
                    Generar pin
                  </button>
                  <span className="px-2 py-1 border text-muted bg-white">{`${
                    formik.values.latitude ?? ""
                  }, ${formik.values.longitude ?? ""}`}</span>

                  {formik.errors.latitude ? (
                    <div className="invalid text-sm">
                      {formik.errors.latitude}
                    </div>
                  ) : null}
                </Col>
                <Col md={6} xs={12}>
                  <label>Categoria</label>
                  <select
                    id="categoryId"
                    className="form-control"
                    value={formik.values.categoryId ?? ""}
                    onChange={formik.handleChange}
                  >
                    <option value="">-</option>
                    {categories &&
                      categories.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        )
                      })}
                  </select>

                  {formik.errors.categoryId ? (
                    <div className="invalid text-sm">
                      {formik.errors.categoryId}
                    </div>
                  ) : null}
                </Col>
                <Col md={6} xs={12}>
                  <label>Etapa</label>
                  <select
                    id="stageId"
                    className="form-control"
                    value={formik.values.stageId ?? ""}
                    onChange={formik.handleChange}
                  >
                    <option value="">-</option>
                    {stages &&
                      stages.map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.name}
                          </option>
                        )
                      })}
                  </select>

                  {formik.errors.stageId ? (
                    <div className="invalid text-sm">
                      {formik.errors.stageId}
                    </div>
                  ) : null}
                </Col>

                <Col className="mt-2" xs={12}>
                  <label>Nota</label>
                  <textarea className="form-control"></textarea>

                  {formik.errors.note ? (
                    <div className="invalid text-sm">{formik.errors.note}</div>
                  ) : null}
                </Col>

                <Col className="my-2" xs={12}>
                  <input
                    id="imageUploadPrimary"
                    className="form-control d-none"
                    type="file"
                    // multiple
                    capture="camera"
                    accept="image/*"
                    onChange={obj => uploadImg(obj, "image")}
                  />
                  <label
                    className="btn btn-secondary btn-sm btn-block"
                    htmlFor="imageUploadPrimary"
                  >
                    <i className="fas fa-camera me-2"></i>
                    Imagen Principal
                  </label>

                  {formik.values.image ? (
                    <img
                      alt=""
                      className="img-thumbnail my-2"
                      style={{ width: "200px" }}
                      id="image"
                      src={`${Settings.BasePath}/files/${formik.values.image}`}
                    />
                  ) : null}
                  {formik.errors.image ? (
                    <div className="invalid text-sm">{formik.errors.image}</div>
                  ) : null}
                  {/* <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={async () => {
                      toggleTakePicture(null, "image");
                    }}
                  >
                    Agregar Imagen
                  </button> */}
                  <br />
                  <input
                    id="imageUploadSecondary"
                    className="form-control d-none"
                    type="file"
                    // multiple

                    capture="camera"
                    accept="image/*"
                    onChange={obj => uploadImg(obj, "image2")}
                  />
                  <label
                    className="btn btn-secondary btn-sm btn-block"
                    htmlFor="imageUploadSecondary"
                  >
                    <i className="fas fa-camera me-2"></i>
                    Imagen Secundaria (opcional)
                  </label>

                  {formik.values.image2 ? (
                    <img
                      alt=""
                      className="img-thumbnail  my-2 ms-2"
                      style={{ maxHeight: "200px" }}
                      id="image"
                      src={`${Settings.BasePath}/files/${formik.values.image2}`}
                    />
                  ) : null}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Form>
        <Modal.Footer className="p-1">
          <button
            disabled={isLoading}
            className="btn btn-primary"
            onClick={() => {
              formik.submitForm()
            }}
          >
            Guardar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddEditPlace
