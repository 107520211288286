import React, { useEffect } from "react"
import API from "../../common/API"
import { Card, Col, Row } from "react-bootstrap"
import { use } from "i18next"
import { useTranslation } from "react-i18next"
import { parsedUser } from "../../common/GetCurrentUser"
import { useHistory } from "react-router-dom"
import Settings from "../../common/Settings"
const CustomerPlaceList = props => {
  const [records, setRecords] = React.useState([])
  const user = parsedUser()
  const history = useHistory()
  const { t } = useTranslation("global")
  // Main request
  const request = async (avoidZoom?) => {
    let Query = `customerId=${props.customerId}&OrganizationId=${user.OrganizationId}`

    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("place/list", Query)

    if (requestAPI.data.status === "ok") {
      let result = requestAPI.data.response
      setRecords(result)
    }
  }
  useEffect(() => {
    request()
  }, [props.customerId])
  return (
    <Card className="mb-4">
      <Card.Header>
        <Card.Title>{t("Places")}</Card.Title>
      </Card.Header>
      <Card.Body className="bg-light">
        {records &&
          records.length > 0 &&
          records.map((item: any, index: number) => {
            console.log(item)
            return (
              <Row
                key={index}
                className="clickable"
                onClick={() => {
                  history.push(`/places/${item.id}`)
                }}
              >
                <Col xs={"auto"}>
                  <img
                    src={`${Settings.BasePathForAPI}file/getThumbnail?filename=${item.image} `}
                    loading="lazy"
                    alt=""
                    className="img-fluid rounded img-thumbnail"
                    style={{ maxHeight: "100px" }}
                  />
                </Col>
                <Col xs={12} md={8}>
                  <b>{item.name}</b>
                  <p>
                    {`${item.address}`}
                    <br />
                    {`${item.city}, ${item.sector}, ${item.country}`}
                  </p>
                  <p>
                    {/* Status */}
                    <i
                      className={
                        (item.placeStatus && item.placeStatus.icon
                          ? item.placeStatus.icon
                          : " fa fa-circle ") + " me-2"
                      }
                      style={{
                        color: item.placeStatus && item.placeStatus.color,
                      }}
                    ></i>
                    {item.placeStatus ? item.placeStatus.name : "N/A"}
                    {/* Assigned To */}
                    <span className="mx-2">|</span>
                    {item.assignedTo ? (
                      <button
                        className="clickable btn btn-outline-primary btn-sm"
                        // onClick={() => toggleAssignUsers()}
                      >
                        {item.assignedToObj.firstName}{" "}
                        {item.assignedToObj.lastName}
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary btn-sm ms-2"
                        // onClick={() => toggleAssignUsers()}
                      >
                        {t("Assign")}
                      </button>
                    )}

                    {/* Stage */}
                    <span className="mx-2">|</span>
                    {item.stage.name}
                  </p>
                </Col>
                <Col className="col-12">
                  <hr />
                </Col>
              </Row>
            )
          })}
      </Card.Body>
    </Card>
  )
}

export default CustomerPlaceList
