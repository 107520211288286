import { useTranslation } from "react-i18next"
import CRUDField from "../core/genericCRUD/CRUDField"
import { parsedUser } from "../../common/GetCurrentUser"
const user = parsedUser()
const CustomerDTO: CRUDField[] = [
  {
    name: "id",
    title: "id",
    htmlType: "hidden",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "name",
    title: "Name",
    htmlType: "text",
    dbType: "string",
    required: true,
    groupNumber: 0,
    containerClass: " col-12",
  },
  {
    name: "identificationNumber",
    title: "Identification Number",
    htmlType: "text",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "reference",
    title: "Reference",
    htmlType: "text",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "organizationId",
    title: "organizationId",
    htmlType: "hidden",
    dbType: "string",
    required: true,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
    value: user ? user.OrganizationId : null,
  },
  {
    name: "address",
    title: "Address",
    htmlType: "text",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "sector",
    title: "Sector",
    htmlType: "text",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "city",
    title: "City",
    htmlType: "text",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
  {
    name: "country",
    title: "Country",
    htmlType: "text",
    dbType: "string",
    required: false,
    groupNumber: 0,
    containerClass: "col-md-6 col-12",
  },
]

export default CustomerDTO
