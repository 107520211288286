import React, { useEffect, useState } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { parsedUser } from "../../common/GetCurrentUser"
import API from "../../common/API"
import { useTranslation } from "react-i18next"
import { use } from "i18next"

const AssignCustomer = props => {
  const user = parsedUser()
  const { t } = useTranslation("global")
  const [records, setRecords] = useState([])
  const [search, setSearch] = useState("")
  const bindDataRemote = async () => {
    let Query = "OrganizationId=" + user.OrganizationId
    Query += `&search=${search}`
    //   var response = await Http.GetJsonAsync<pleelo.Helpers.APIModelRequestHelper>("/api/Expense/List?" + Query);
    let requestAPI = await API.getAction("customer/list", Query)

    if (requestAPI.data.status === "ok") {
      var result = requestAPI.data.response
      setRecords(result)
    }
  }

  const assignCustomer = async obj => {
    const query = "customerId=" + obj.id + "&placeId=" + props.place.id
    const request = await API.getAction("customer/assignPlace", query)
    props.toggle()
  }

  useEffect(() => {
    bindDataRemote()
  }, [search])

  useEffect(() => {
    bindDataRemote()
  }, [])

  return (
    <>
      <Modal show={props.show} onHide={props.toggle} className="modalRight">
        <Modal.Header closeButton>
          <Row className="w-100">
            <Col>
              <h5>{t("Assign Customer")}</h5>
            </Col>
            <Col>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder={t("Search")}
                onChange={e => {
                  console.log(e.target.value)
                  setSearch(e.target.value)
                }}
                value={search}
              />
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body className="p-0" style={{ overflow: "auto" }}>
          <div className="table-responsive">
            <table className="table table-striped">
              <tbody>
                {records &&
                  records.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.name}</td>
                        <td className="text-end">
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              console.log("assign")
                              assignCustomer(item)
                            }}
                          >
                            {t("Assign")}
                          </button>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AssignCustomer
