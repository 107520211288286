
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faBuilding, faCalendar, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from 'react-bootstrap';

import logoCapital from "../../assets/logos/logoShearlyCap.png";
import logoInitiative from "../../assets/logos/logoShearlyI.png";

import NOTIFICATIONS_DATA from "../../data/notifications";
import { Routes } from "../../routes";
import GetCurrentUser, { parsedUser } from "../../common/GetCurrentUser";
import { userAvatar } from "../../common/helpers";
import { parseJwt } from "../../common/helpers";

import { Search } from "semantic-ui-react"

import { SocketContext } from "../../Context/socket";


import { useHistory } from "react-router-dom";
import OrganizationBox from "../Settings/OrganizationBox";
import Conversations from "../Chat/Conversations";
import API from "../../common/API";
import { useTranslation } from "react-i18next";


export default (props) => {
  const { t } = useTranslation('global')
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);
  const user = parsedUser();
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  const history = useHistory();
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [showShare, setShowShare] = useState(false);


  const toggleShare = () => {
    setShowShare(!showShare);
  }


  const socket = useContext(SocketContext);



  const Notification = (props) => {
    const { link, sender, image, time, message, read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <image src={image} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };


  const [showChat, setShowChat] = useState(false);
  const toggleChat = () => {

    setShowChat(!showChat)
    if (!showChat === false) {
      setConversationSelected(null);
    }
  }
  const [conversationSelected, setConversationSelected] = useState(null);
  const onPersonalClick = obj => {
    setConversationSelected(obj);
    toggleChat();
  }

  const [showCall, setShowCall] = useState(false);
  const [callData, setCallData] = useState(null);

  const toggleCall = (obj, caller, isVideoCall) => {
    if (obj) {
      setCallData({
        conversation: obj,
        requestedBy: user,
        isVideoCall: isVideoCall
      })
      if (caller === true) {

        socket.invoke("RequestToStartCall", obj.id, user.ID)
      }

    }
    setShowCall(!showCall);
  }



  let chatInterval = null;

  const [chatCount, setChatCount] = useState(0);
  const requestChatCount = async () => {

    var response = await API.getAction("Chat/UnreadCount", "userId=" + user.ID);

    if (response.data.status === "ok") {
      setChatCount(response.data.response)
    }

  }


  const [showSearch, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const handleSearchChange = React.useCallback(async (e, data) => {

    setSearchValue(data.value);
    if ((data.value || "").length > 2) {
      //Search remote
      let request = await API.getAction(
        "place/list?name=" + data.value + "&organizationId=" + user.OrganizationId
      )

      if (request.data.status === "ok" && request.data.response) {
        let response = request.data.response
        let finalResponse = response.map(item => {
          console.log(item)
          return {
            title: item.name,
            description: (item.address ? item.address : "") + (item.city ? ", " + item.city : "") + (item.sector ? ", " + item.sector : ""),
            object: item,
          }
        })
        console.log(finalResponse)
        setSearchOptions(finalResponse)
      }
    }
  }, [])

  useEffect(() => {
    requestChatCount();
    chatInterval = setInterval(() => {
      requestChatCount();
    }, 10000)

    return () => {
      clearInterval(chatInterval)
    }
  }, [])

  return (
    <>
      {showChat && <Conversations show={showChat} toggle={toggleChat} />}
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0 bg-white shadow">
        <Container className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center d-none d-md-block">

              <OrganizationBox readOnly />


            </div>
            <Nav className="align-items-center ms-auto">

              <Search
                name={"search"}
                className=""
                value={searchValue ?? ""}
                loading={searchLoading}
                results={searchOptions}
                onSearchChange={handleSearchChange}
                resultRenderer={item => {
                  console.log(item)
                  return <>
                    <label>{item.title}</label>
                    <br />
                    <small className="text-muted">{item.description}</small>
                  </>
                }}
                onResultSelect={(e, data) => {
                  console.log(data)
                  // props.updateField(
                  //   model.name,
                  //   data.results.object.formattedAddress
                  // )

                  history.push(Routes.PlaceDetails.clean + data.result.object.id)
                  //  props.selectMapSearch(data.result.object)
                }}
              />
              <button className="btn" onClick={() => {
                history.push(Routes.Home.path)
              }}>
                <i className="fa fa-home me-2"></i>

                <br />
                <small>   {t('Home')} </small>
              </button>
              <button className="btn me-2"
                onClick={() => {
                  history.push(Routes.Dashboard.path)
                }}>
                <i className="fa-solid fa-dashboard me-2"></i>
                <br />
                <small> {t('Dashboard')} </small>
                {chatCount > 0 && <span className="text-white noty-badge badge bg-danger">{chatCount}</span>}
              </button>

              <button className="btn" onClick={() => {
                history.push(Routes.Customers.path)
              }}>
                <i className="fa fa-users me-2"></i>
                <br />
                <small>{t('Customers')} </small>
              </button>
              <button className="btn me-2" onClick={() => toggleChat()}>
                <i className="fa-regular fa-comments me-2"></i>
                <br />
                <small> {t('Chats')} </small>
                {chatCount > 0 && <span className="text-white noty-badge badge bg-danger">{chatCount}</span>}
              </button>
              {/* <button className="btn me-2" onClick={() => toggleChat()}>
                <i className="fa-solid fa-truck me-2"></i>
                <br />
                <small> {t('Vehiculos')} </small>
                {chatCount > 0 && <span className="text-white noty-badge badge bg-danger">{chatCount}</span>}
              </button> */}

              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={"button"} className=" btn btn-primary btn-sm">
                  {user && user.Email.substring(0, 1).toUpperCase()}

                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item className="fw-bold" onClick={() => history.push(Routes.Profile.path)}>
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" />
                    {t('Profile')}
                  </Dropdown.Item>
                  {/* <Dropdown.Item className="fw-bold" onClick={() => toggleShare()}>
                    <FontAwesomeIcon icon={faCalendar} className="me-2" /> Calendario Global
                  </Dropdown.Item> */}
                  <Dropdown.Item className="fw-bold" onClick={() => history.push(Routes.Settings.path)}>
                    <FontAwesomeIcon icon={faCog} className="me-2" /> {t('Settings')}
                  </Dropdown.Item>

                  <Dropdown.Item className="fw-bold" onClick={() => history.push(Routes.SelectOrganization.path)}>
                    <FontAwesomeIcon icon={faBuilding} className="me-2" />
                    {t('Change Organization')}
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold d-none">
                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                  </Dropdown.Item>


                  <Dropdown.Divider />

                  <Dropdown.Item className="fw-bold"
                    onClick={() => {
                      history.push(Routes.SignIn.path)
                    }}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> {t('Log Out')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </>
  );
};
