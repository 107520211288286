import { use } from "i18next"
import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import API from "../../common/API"
import CustomerPlaceList from "./CustomerPlaceList"
function CustomerDetails() {
  const { t } = useTranslation("global")
  const { id } = useParams()
  const [model, setModel] = useState<any>(null)

  const bindDataRemote = async () => {
    const request = await API.getAction("customer/details?id=" + id)
    if (request.data.status === "ok") {
      const data = request.data.response
      setModel(data)
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    bindDataRemote()
  }, [id])

  if (model == null) {
    return <></>
  }
  return (
    <Container className="py-4">
      <Row>
        <Col xs={12} md={9}>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                {/* <Col xs={"auto"}>
                  <img
                    src={"https://picsum.photos/200"}
                    className="img-fluid rounded img-thumbnail"
                    alt=""
                  ></img>
                </Col> */}
                <Col xs={12} md={8}>
                  <h3>{model.name}</h3>
                  <p>
                    {`${model.address}`}
                    <br />
                    {`${model.city}, ${model.sector}, ${model.country}`}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={3}>
          <Card className="mb-4">
            <Card.Body>
              <Row>
                <Col>
                  <button className="btn btn-primary btn-block">
                    <i className="fa fa-edit me-2"></i>
                    {t("Edit")}
                  </button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <CustomerPlaceList customerId={model.id} />
    </Container>
  )
}

export default CustomerDetails
