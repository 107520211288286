import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";

// pages

// components
import Sidebar from "./components/Common/Sidebar";
import Navbar from "./components/Common/Navbar";
import Footer from "./components/Common/Footer";
import Preloader from "./components/Preloader";


import Profile from './components/Profile/Profile';
import Login from './components/Profile/Login';
import Register from './components/Profile/Register';
import ForgotPassword from './components/Profile/ForgotPassword';


import DashboardOverview from './components/dashboard/DashboardOverview';
import { parseJwt } from './common/helpers';
import GetCurrentUser from './common/GetCurrentUser';
import { socketComponent, SocketContext } from './Context/socket';
import HomePage from './pages/HomePage';
import SettingsPage from './pages/SettingsPage';
import CustomerPage from './pages/Customers/CustomerPage.tsx';
import SelectOrganization from './components/Organizations/SelectOrganization';
import OneSignal from 'react-onesignal';
import { Container } from 'react-bootstrap';
import DashboardPage from './pages/DashboardPage';
import CustomerDetailPage from './pages/Customers/CustomerDetailPage';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {

    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);


  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        <Preloader show={loaded ? false : true} />
        {/* <OmniBar /> */}
        {/* <Sidebar repsly={rest.repsly} /> */}
        <Navbar />
        <Container fluid className='px-0'>

          <Component {...props} />

        </Container>


      </>
    )
    }
    />
  );
};

export default () => {


  const runOneSignal = async () => {
    await OneSignal.init({ appId: 'a46b5373-ac0b-4dd0-b92a-fc093cac876e', allowLocalhostAsSecureOrigin: true });
    OneSignal.Slidedown.promptPush();
  }
  useEffect(() => {
    runOneSignal();
  }, [])
  return (

    <Switch>
      {/* pages */}
      <RouteWithSidebar exact path={Routes.Home.path} component={HomePage} />
      <RouteWithSidebar exact path={Routes.CustomerDetails.path} component={CustomerDetailPage} />
      <RouteWithSidebar exact path={Routes.Dashboard.path} component={DashboardPage} />
      <RouteWithSidebar exact path={Routes.chat.path} component={HomePage} />
      <RouteWithSidebar exact path={Routes.PlaceDetails.path} component={HomePage} />
      <RouteWithSidebar exact path={Routes.Customers.path} component={CustomerPage} />
      <Route exact path={Routes.SelectOrganization.path} component={SelectOrganization} />

      <Route path={Routes.SignIn.path} component={Login} />

      <RouteWithSidebar exact path={Routes.Settings.path} component={SettingsPage} />
      <Redirect to={Routes.SignIn.path} />
    </Switch>

  )
};
