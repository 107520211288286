
export const Routes = {
    // pages
    Home: { path: "/" },
    Dashboard: { path: "/dashboard" },
    SignIn: { path: "/signin" },
    Profile: { path: "/profile" },
    Register: { path: "/Register" },
    ForgotPassword: { path: "/ForgotPassword" },
    ResetPassword: { path: "/ResetPassword" },

    RepslyPhoto: { path: "/repslyPhoto", clean: "/repslyPhoto/" },
    RepslyCustomers: { path: "/repslyCusotmers", clean: "/repslycusotmers/" },
    RepslyVisit: { path: "/repslyVisit", clean: "/repslyVisit/" },

    Categories: { path: "/Categories", clean: "/categories/" },
    Stages: { path: "/Stages", clean: "/stages/" },
    Settings: { path: "/settings", clean: "/settings/" },

    Customers: { path: "/customers", clean: "/customers/" },
    CustomerDetails: { path: "/customers/details/:id", clean: "/customers/details/" },
    Places: { path: "/places", clean: "/places" },
    PlaceDetails: { path: "/places/:placeId", clean: "/places/" },

    SelectOrganization: { path: "/SelectOrganization" },
    chat: { path: "/chat/:chatId", clean: "/chat/" },

};